<template>
    <a-space direction="vertical" style="width: 100%" v-if="visible">
      <a-alert :message="message" :type="type" :closable="closable" @close="handleClose" />
    </a-space>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    data() {
      return {
        message: '',
        type: 'info',
        visible: false,
        closable: true,
      };
    },
    methods: {
      showAlert(msg, alertType = 'info') {
        this.message = msg;
        this.type = alertType;
        this.visible = true;
      },
      handleClose() {
        this.visible = false;
      }
    }
  });
  </script>
  
  <style scoped>
  a-alert {
    margin-bottom: 10px;
  }
  </style>
  