import { createVNode, render } from 'vue';
import CustomAlert from '../components/CustomAlert.vue';

let alertContainer = null;
let alertInstance = null;

const AlertPlugin = {
  install(app) {
    if (!alertContainer) {
      alertContainer = document.createElement('div');
      document.body.appendChild(alertContainer);

      // 创建 VNode 实例
      const alertVNode = createVNode(CustomAlert);
      render(alertVNode, alertContainer);

      // 获取组件实例
      alertInstance = alertVNode.component?.proxy;
    }

    // 全局注册 $alert 函数
    app.config.globalProperties.$alert = (message, type = 'info') => {
      if (alertInstance) {
        alertInstance.showAlert(message, type); // 调用组件实例方法
      }
    };
  }
};

export default AlertPlugin;
