<template>
  <!-- <CustomAlert ref="customAlert" /> -->
<router-view/>
</template>

<script>
// import User from './components/user.vue'

export default {
  name: 'App',
  components: {
    // User
  },
  mounted(){
    
  }

}
</script>

<style>
 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background: red; */
  width: 100%;
  /* margin-top: 60px; */

}

</style>
