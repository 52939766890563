import { createI18n } from 'vue-i18n'
import en from './en'
import cn from './cn'
 
const messages = {
  en, cn
}
 
 
const localeData = {
  legacy: false, // composition API
  globalInjection: true, //全局生效$t
  locale: cn, // 默认cn翻译
  messages
}
 
export function setupI18n (app) {
  const i18n = createI18n(localeData)
  app.use(i18n)
}