import { createRouter,createWebHistory} from "vue-router";
const routerHistorys = createWebHistory()
 
  const router = createRouter({
    history: routerHistorys,
    routes: [
        {
            path:'/',
            name:'root',
            // redirect:'user'
            component: ()=>import('@/components/user.vue')
        },
      {
        path: '/user', 
        name:'user',
        component: ()=>import('@/components/user.vue')
      },
    //   {
    //     path: '/review1', 
    //     name:'review1',
    //     component: ()=>import('@/components/review1.vue')
    //   },
      {
        path: '/review', 
        name:'review',
        component: ()=>import('@/components/review.vue')
      },
      {
        path: '/privicy', 
        name:'privicy',
        component: ()=>import('@/components/privicy.vue')
      },
      {
        path: '/pridebook', 
        name:'pridebook',
        component: ()=>import('@/components/pridebook.vue')
      },
      {
        path: '/bookshelf', 
        name:'bookshelf',
        component: ()=>import('@/components/readbook.vue')
      },
      {
        path: '/chat', 
        name:'chat',
        component: ()=>import('@/components/chat.vue')
      },
      {
        path: '/badges', 
        name:'badges',
        component: ()=>import('@/components/badgeshowzone.vue')
      },
      {
        path: '/software', 
        name:'software',
        component: ()=>import('@/components/software.vue')
      },

      {
        path: '/version', 
        name:'version',
        component: ()=>import('@/components/version.vue')
      },
      {
        path: '/premium', 
        name:'premium',
        component: ()=>import('@/components/member.vue')
      },
      {
        path: '/goalmanage', 
        name:'goalmanage',
        component: ()=>import('@/components/goal.vue')
      },
      {
        path: '/mapofgoal', 
        name:'mapofgoal',
        component: ()=>import('@/components/mapofgoal.vue')
      },
      {
        path: '/tagmanage', 
        name:'tagmanage',
        component: ()=>import('@/components/tagmanage.vue')
      },
      {
        path: '/factormanage', 
        name:'factormanage',
        component: ()=>import('@/components/algofactor.vue')
      },
      {
        path: '/travel', 
        name:'travel',
        component: ()=>import('@/components/travelmainpage.vue')
      },
      {
        path: '/docweb', 
        name:'docweb',
        component: ()=>import('@/components/doc.vue')
      },

      {
        path: '/setting', 
        name:'setting',
        component: ()=>import('@/components/setting.vue')
      },
      {
        path: '/newsetting', 
        name:'newsetting',
        component: ()=>import('@/components/newsetting.vue')
      },
      {
        path: '/goaltree', 
        name:'goaltree',
        component: ()=>import('@/components/goaltree.vue')
      },
      {
        path: '/taskmanager', 
        name:'taskmanager',
        component: ()=>import('@/components/mainpage.vue')
      },
      {
        path: '/reference', 
        name:'reference',
        component: ()=>import('@/components/reference.vue')
      },
      {
        path: '/timer', 
        name:'timer',
        component: ()=>import('@/components/timer.vue')
      },
      {
        path: '/countdown', 
        name:'countdown',
        component: ()=>import('@/components/countdowntimer.vue')
      },
      {
        path: '/glass', 
        name:'countdown',
        component: ()=>import('@/components/timer/Hourglass.vue')
      },
      {
        path: '/clock', 
        name:'clock',
        component: ()=>import('@/components/timer/clock.vue')
      },
      {
        path: '/3lab', 
        name:'3lab',
        component: ()=>import('@/components/ThreeExperiment.vue')
      },
      {
        path: '/heatmap', 
        name:'heatmap',
        component: ()=>import('@/components/reviews/heatmap.vue')
      },
      {
        path: '/sche', 
        name:'sche',
        component: ()=>import('@/components/schedulemanager.vue')
      },

      { path: "/:catchAll(.*)", component:()=>import('@/components/error404.vue') }
    ]
  })
 
export default router