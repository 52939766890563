import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'quasar/dist/quasar.css'
import 'ant-design-vue/dist/antd.css';
import store from './store';


import 'material-design-icons-iconfont/dist/material-design-icons.css'
import "./css/global.css"
import i18n from './i18n'
import dragable from './dragable';
const $ = require('jquery');
import vSelect from 'vue-select';
import AlertPlugin from './plugins/AlertPlugin'; // 引入 Alert 插件
import { setupI18n } from './lang/index'
// 引入 Quasar 和 Quasar 选项
import { Quasar , Notify} from 'quasar';
// import configure_options from './configure';
// import websocket from './channel/websocket';
// const env = configure_options["env"];
// let wsUrl = "ws://127.0.0.1:8081/v1/ws"; // 默认开发地址
// if (env === "production") {
//   wsUrl = "wss://www.cosmosatom.cc/v1/ws";
// }
// websocket.connect(wsUrl); // 初始化 WebSocket


window.$ = $;
window.jQuery = $;
String.prototype.hashCode = function(){
    if (Array.prototype.reduce){
        return this.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
    } 
    var hash = 0;
    if (this.length === 0) return hash;
    for (var i = 0; i < this.length; i++) {
        var character  = this.charCodeAt(i);
        hash  = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}




  const app = createApp(App)
  
i18n.global.locale = 'cn'
  setupI18n(app)

  app.use(Quasar, {
    components: [
        'QSlider',
        // 其他组件
        'QBadge',
      ],
    plugins: {Notify }, // 如果你有需要，可以在此引入 Quasar 插件
    config: {
        
  
      }
  });
//   Dark.set(true); 
  app.use(i18n)
//app.provide('$', jquery);
app.use(router)
// app.config.globalProperties.$websocket = websocket;
app.use(store)
app.use(AlertPlugin);
app.use(Antd)
app.directive('draggable', dragable); 
app.mount('#app')
app.component('v-select', vSelect);